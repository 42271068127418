<template>
    <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="item"
      :title="model && item.id ? `Detalhes do chamado ` + item.ticket_number : ``"
      icon="mdi-buffer"
      v-bind:show.sync="dialog"
      maxWidth="50%"
      :actions="user.is_internal ? true : false"
    >
    <template v-slot:form>
            <v-row class="mt-1">
                <v-col cols="12" v-if="message">
                    <v-alert
                        outlined
                        color="warning"
                        prominent
                        border="left"
                        >
                        {{ message }}
                        </v-alert>
                </v-col>
                <FormSelectUser
                    v-model="item.author_id" 
                    :item="item.author"
                    label="Autor*" 
                    md="8"
                    outlined
                    hide-no-data
                    :error="errors.length > 0"
                    :error-messages="errors.author_id"
                    disabled
                />
                <FormTicketSelectCategory 
                    v-model="item.ticket_category_id" 
                    :item="item.category"
                    label="Categoria*"
                    md="4"
                    outlined
                    hide-no-data
                    :error="errors.length > 0"
                    :error-messages="errors.ticket_category_id"
                    :disabled="!user.is_internal"
                />
                <EmcFormTextField 
                    v-model="item.subject" 
                    label="Assunto*" 
                    md="8"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.subject"
                    disabled
                />
                <FormTicketSelectType 
                    v-model="item.ticket_type_id" 
                    :item="item.type"
                    md="4"
                    outlined
                    hide-no-data
                    :error="errors.length > 0"
                    :error-messages="errors.ticket_type_id"
                    :disabled="!user.is_internal"
                />    
                <EmcFormTextArea
                    v-model="item.description" 
                    label="Descrição*" 
                    md="12"
                    outlined
                    auto-grow
                    :error="errors.length > 0"
                    :error-messages="errors.description"
                    placeholder="Informe todos os detalhes do seu chamado..."
                    disabled
                />
                <FormSelectUser
                    v-if="user.is_internal"
                    v-model="item.assigned_to_id" 
                    :item="item.assigned"
                    label="Atribuído para" 
                    :md="user.is_internal ? 5 : 8"
                    outlined
                    hide-no-data
                    :error="errors.length > 0"
                    :error-messages="errors.assigned_to_id"
                    is-user-internal
                    clearable
                />

                <v-col cols="12" md="3"  v-if="user.is_internal && item.id">
                    <emc-calendar-date-picker2 
                        :model.sync="model.resolution_date" 
                        label="Data Prevista"
                        :errorMessages="errors.resolution_date" 
                        :show="dialog"
                        outlined
                    />
                </v-col>
                
                <FormSelectStatus
                    v-model="item.ticket_status_id"
                    :item="item.status"
                    label="Status*" 
                    md="4"
                    outlined
                    hide-no-data
                    :error="errors.length > 0"
                    :error-messages="errors.ticket_status_id"
                    :disabled="!user.is_internal"
                />

            </v-row>

            <v-row class="mt-5 mb-5">
                <v-divider></v-divider>
            </v-row>

            <v-row>
                <v-col cols="12" md="12">
                    <div class="text-subtitle-1 transition-swing font-weight-medium">
                        Anexo(s):
                    </div>
                </v-col>
                <v-col cols="12" md="12">
                    <div>
                        <v-row>
                            <v-col cols="12" sm="3" v-for="(file, i) in item.attachments" :key="i">
                                <v-btn outlined :color="extensions[file.file_type].color" small class="ma-2" width="200px" :href="file.full_url_file + `?token=` + token">
                                    <v-icon left small :color="extensions[file.file_type].color">{{ extensions[file.file_type].icon }}</v-icon>
                                    <span class="d-inline-block text-truncate"
                                        style="max-width: 150px;">{{ file.file_name }}</span>
                                </v-btn>
                            </v-col>

                            <v-col cols="12" v-if="item.attachments == undefined || item.attachments.length == 0">
                                <span class="text-captian">Este chamado não possui anexo.</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-5 mb-5">
                <v-divider></v-divider>
            </v-row>

            <v-row>
                <v-col cols="12" md="12">
                    <div class="text-subtitle-1 transition-swing font-weight-medium">
                        Cadastrar comentário:
                    </div>
                </v-col>
                <EmcFormTextArea
                    label="Comentário"
                    v-model="comment"
                    md="12"
                    outlined
                    auto-grow
                    :error="errors.length > 0"
                    :error-messages="errors.description"
                    placeholder="Informe todos os detalhes do seu comentário..."
                />

                <v-col cols="12" md="12">
                    <EmcFormFile 
                        v-model="files"
                        label="Anexar no comentário"
                        name="files"
                        multiple
                        outlined
                        :error="hasValidationErrors.length ? true : false"
                    />
                </v-col>

                <!-- Se houver mensagens de validação de anexo do comentário -->
                <v-alert color="error" outlined v-if="hasValidationErrors.length">
                    <div class="text-subtitle2">
                        Verifique os arquivos:
                    </div>
                    <div class="mt-2">
                        <ul>
                            <!-- Usa v-for para percorrer e exibir apenas as mensagens de validação de array -->
                            <li v-for="(item, index) in hasValidationErrors" :key="index">
                                {{ item[1][0] }}
                            </li>
                        </ul>
                    </div>
                </v-alert>

                <v-col v-if="user.is_internal" cols="12" md="6">
                    <s-switch inset text-helper="Comentário vísivel apenas para usuários internos do SysClass" v-model="isInternal" label="Comentário Interno" />
                </v-col>
                <v-col cols="12" :md="user.is_internal ? 6 : 12">
                    <div class="text-right">
                        <v-btn
                        color="primary"
                        class="ma-2 white--text"
                        small
                        :loading="savingComments"
                        :disabled="savingComments"
                        @click="saveComment"
                        right
                    >
                        Salvar comentário
                        <v-icon
                            right
                            dark
                            small
                        >
                            mdi-content-save
                        </v-icon>
                    </v-btn>
                    </div>
                </v-col>
                
            </v-row>

            <v-row class="mt-5 mb-5">
                <v-divider></v-divider>
            </v-row>

            <v-row class="mt-1">
                <v-col cols="12" md="12">
                    <div class="text-subtitle-1 transition-swing font-weight-medium">
                        Comentários:
                    </div>
                </v-col>
                <v-col cols="12" md="12">
                    <v-card class="elevation-0" v-if="commentsReverse.length">
                        <v-card-text>
                            <v-timeline
                                align-top
                                dense
                            >
                            <v-timeline-item
                                    color="grey"
                                    small
                                    v-for="(comment, i) in commentsReverse" :key="i"
                                >
                                    <v-row class="pt-1">
                                        <v-col cols="3">
                                            <span class="text-caption">{{ comment.created_at }}</span>
                                        </v-col>
                                        <v-col>
                                            <strong v-if="user.id == comment.user_id || user.is_internal">{{ comment.user.name }}</strong>
                                            <strong v-else>Suporte SysClass</strong>
                                            <div class="text-caption">
                                                {{ comment.description }}
                                            </div>
                                            <v-btn
                                                icon
                                                v-for="(file, i) in comment.attachments"
                                                :key="i"
                                                :href="file.full_url_file + `?token=` + token"
                                            >
                                            <v-icon :color="extensions[file.file_type].color">{{ extensions[file.file_type].icon }}</v-icon>
                                            </v-btn>
                                            <small v-if="comment.is_internal == 1">[ Comentário Interno ]</small>
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>

                            </v-timeline>
                            
                        </v-card-text>
                    </v-card>

                    <div v-else>
                        <span class="text-captian">Este chamado não possui comentário.</span>
                    </div>
                </v-col>
            </v-row>

        </template>

    </sys-register-base>
</template>

<script>

import FormTicketSelectCategory from '../category/SelectCategory.vue'
import FormTicketSelectType from '../type/SelectType.vue'
import FormSelectUser from '../../../../user/components/SelectUser.vue'
import FormSelectStatus from '../status/SelectStatus.vue'

import { mapActions, mapState } from 'vuex'

export default {
    name: "TicketDetails",
    data () {
        return {
            loading: false,
            savingComments: false,
            message: null,
            errors: {},
            extensions: {
                pdf: { icon: "mdi-file-pdf", color: "red" } ,
                png: { icon: "mdi-file-image", color: "teal" },
                jpg: { icon: "mdi-file-image", color: "teal" },
                jpeg: { icon: "mdi-file-image", color: "teal" },
                xlsx: { icon: "mdi-microsoft-excel", color: "green" },
                xls: { icon: "mdi-microsoft-excel", color: "green" },
                csv: { icon: "mdi-microsoft-excel", color: "green" },
                txt: { icon: "mdi-microsoft-excel", color: "green" },
                doc: { icon: "mdi-microsoft-word", color: "indigo" },
                docx: { icon: "mdi-microsoft-word", color: "indigo" }
            },
            comment: '',
            isInternal: false,
            files: []
        }
    },
    components: {
        FormTicketSelectCategory,
        FormTicketSelectType,
        FormSelectUser,
        FormSelectStatus
    },
    props: {
        show: { type: Boolean, default: false },
        model: { default: null }
    },
    computed: {
        ...mapState('auth', ['token', 'user']),
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        item: {
            get() {
                return this.model
            },
            set(value) {
                this.$emit('model', value)
            }
        },
        commentsReverse() {

            if(this.item.comments != undefined) {

                //Removendo a reatividade para fazer a reversão do array
                var comments = this.item.comments.slice();
                return comments.reverse()
            }

            return []
        },
        hasValidationErrors() {

            if(Object.keys(this.errors).length) {

                const arrayDePara = Object.entries(this.errors);
                // Verifica se há mensagens de validação de array
                return arrayDePara.filter(message => message[0].startsWith('files.'));
            }

            return []
        }
    },
    watch: {
        show(val){
            
            if(val){
                this.errors = {};
            }
        }
    },
    methods: {
        ...mapActions('ticket', ["ActionTicketsCommentStore", "ActionTicketsUpdate"]),
        saveComment()
        {
            this.errors = {}
            this.message = ''
            this.savingComments = true;

            let formData = new FormData();

            formData.append('description', this.comment)
            formData.append('is_internal', this.isInternal ? 1 : 0)
            //formData.append('id', this.item.id)

            // Adiciona os arquivos ao FormData
            for (const file of this.files) {
                formData.append('files[]', file);
            }

            this.ActionTicketsCommentStore({ params: { id: this.item.id }, form: formData })
                .then((res) => {
                    this.message = null;
                    this.comment = '';
                    this.isInternal = false;
                    this.files = [];
                    
                    this.item.comments.push(res.data);
                })
                .catch((error) => {
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.savingComments = !this.savingComments
                })
        },
        save() {
            
            this.errors = {}
            this.message = ''
            this.loading = true

            const params = {
                id:                 this.item.id,
                ticket_category_id: this.item.ticket_category_id,
                ticket_type_id:     this.item.ticket_type_id,
                ticket_status_id:   this.item.ticket_status_id,
                assigned_to_id:     this.item.assigned_to_id,
                resolution_date:    this.item.resolution_date
            }

            this.ActionTicketsUpdate(params)
                .then((res) => {
                    this.message = null;
                    this.$emit('onCompleted', res.data);
                })
                .catch((error) => {
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loading = !this.loading
                })
        }
    }
}
</script>