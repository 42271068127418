<template>
    <v-col cols="12" md="3">
        <v-card
            class="mx-auto"
            max-width="400"
            max-height="200"
            elevation="0"
            outlined
        >
            <v-card-title>
            <v-icon
                :color="item.color"
                class="mr-12"
                size="64"
            >
                {{ item.icon }}
            </v-icon>
            <v-row align="start">
                <div :class="isSelectedItem ? 'text-caption blue--text text-uppercase mr-4' : 'text-caption grey--text text-uppercase mr-4'">
                {{ item.status }}
                </div>
            </v-row>
            <div>
                <span @click="$emit('click', item)" style="cursor: pointer" >
                    <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span :class="isSelectedItem ? 'text-h3 font-weight-black blue--text' : 'text-h3 font-weight-black'">{{ item.total_tickets }}</span>
                                    <v-icon
                                        v-if="isSelectedItem"
                                        color="error"
                                        class="ml-2"
                                        small
                                    >
                                        mdi-filter-remove-outline
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        color="primary"
                                        class="ml-2"
                                        small
                                    >
                                        mdi-filter-menu-outline
                                    </v-icon>
                                </span>
                            </template>
                            <span v-if="!isSelectedItem">Filtrar chamados {{ item.status }}</span>
                            <span v-else>Limpar Filtro</span>
                    </v-tooltip>
                    
                </span>
            </div>
            <v-spacer></v-spacer>

            
            </v-card-title>

            <v-sheet color="transparent">
                <apexchart type="area" :options="chartOptions" :series="series" height="100"></apexchart>
            </v-sheet>
        </v-card>
    </v-col>
</template>

<script>
export default {
    name: "CardTicketTotal",
    data () {
        return {
            chartOptions: {
                chart: {
                    type: 'line',
                    width: 100,
                    height: 35,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    curve: 'straight'
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function (seriesName) {
                                return ''
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },
            series: [{
                data: Array.from({ length: 10 }, () => Math.floor(Math.random() * 100) + 1)
            }]
        }
    },
    props: {
        item: { type: Object },
        selectedItem: { type: Object },
    },
    computed: {
        isSelectedItem(){
            return (this.selectedItem && this.item) && (this.selectedItem.step == this.item.step);
        }
    },
    
}
</script>