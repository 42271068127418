<template>

    <div>
        <v-row v-if="showGraphs && !myTickets" class="mb-2">
            <CardTicketTotal v-for="(graph, i) in graphs" :key="i" :item="graph" @click="clickGraph" :selectedItem="selectedGraph"/>
        </v-row>
        <main-container icon="mdi-text-box" :title="title">

<!-- <div id="chart">
    <apexchart type="area" height="350" :options="chartOptions" :series="series"></apexchart>
</div> -->

<template v-slot:tools>

    <v-tooltip v-if="!myTickets" bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-2" :color="showGraphs ? 'error' : 'success'" @click="showGraphs = !showGraphs"><v-icon>{{ showGraphs ? 'mdi-eye-off-outline' : 'mdi-eye-check-outline' }}</v-icon></v-btn>
        </template>
        <span>{{  showGraphs ? 'Ocultar indicadores' : 'Exibir indicadores' }}</span>
    </v-tooltip>
    
    <v-btn v-if="selectedGraph" class="mr-2" color="error" small @click="clickGraph(null)">Limpar Filtros<v-icon right>mdi-filter-remove-outline</v-icon></v-btn>
    <v-btn color="primary" small @click="showOpenTicket = true">Abrir um chamado <v-icon right>mdi-text-box-plus</v-icon></v-btn>

</template>
<emc-table-items
    :headers="headers"
    v-bind:params.sync="params"
    v-bind:collections.sync="collections"
    v-bind:meta.sync="meta"
    v-bind:itemEdit.sync="model"
    :loading="loading"
    :showActions="false"
>
    <template v-slot:item.actions="{ item }">
        <EmcButtonIcon 
            :key="item.id" 
            icon="mdi-text-box"
            color="grey"
            :text="`Detalhes do chamado ` + item.ticket_number"
            @click="function(value) { showForm = value; model = item;}"
        />
        <EmcButtonIcon
            v-if="user.is_internal"
            icon="mdi-delete-outline"
            color="error"
            :text="`Excluir chamado ` + item.ticket_number"
            @click="function(value) { itemDelete = item; showDelete = true;}"
        />
    </template>

    <template v-slot:item.ticket_number="{item}">
        {{ item.ticket_number }}
        <small v-if="!myTickets && user.is_internal && item.assigned"><br/>Atríbuído para:<br/>{{ item.assigned.name }}</small>
    </template>

    <template v-slot:item.user="{item}">
        {{ item.author.name }}
        <small><br/>{{ item.institution.name }}</small>
    </template>

    <template v-slot:item.created_at="{item}">
        {{ item.created_at | dateTime }}
        <br/>
        <small>{{ item.created_at_human }}</small>
    </template>

    <template v-slot:item.category.name="{item}">
        <v-chip
            label
            text-color="grey darken-1"
            small
            outlined
        >
        <v-icon left small>{{ item.category.icon }}</v-icon>
            {{ item.category.name }}
        </v-chip>
        
    </template>

    <template v-slot:item.status.name="{item}">
        <v-chip
            label
            text-color="grey darken-1"
            small
            outlined
        >
        <v-icon left small :color="item.status.color">{{ item.status.icon }}</v-icon>
            {{ item.status.name }}
        </v-chip>
    </template>

    <template v-slot:item.type.name="{item}">
        <v-chip
            label
            text-color="grey darken-1"
            small
            outlined
        >
        <v-icon left small>{{ item.type.icon }}</v-icon>
            {{ item.type.name }}
        </v-chip>
    </template>

    <template v-if="!myTickets && user.is_internal" v-slot:footer.prepend>

        <v-switch class="ml-3" inset v-model="onlyMyTickets" label="Exibir chamados atribuídos a mim"/>

    </template>

</emc-table-items>

<OpenTicket 
    :show="showOpenTicket"
    @update:show="(value) => showOpenTicket = value"
    @onCompleted="onSave"
/>

<FormTicketDetails
    :show="showForm"
    @update:show="(value) => showForm = value"
    :model="model"
    @onCompleted="onSave"
/>

<!-- Modal usado para mensagem e ação de exclusão -->
<emc-alert-modal :text="`Deseja excluir o chamado ` + (itemDelete ? itemDelete.ticket_number : '') + `?`" :disabled="deleting" v-bind:show.sync="showDelete">
    <template v-slot:actions>
        <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
    </template>
</emc-alert-modal>

<!-- Alert para mensagens -->
<emc-alert-snackbar :show="!! message.length"  :message="message" :color="error ? 'error' : 'success'"/>

</main-container>

    </div>

</template>

<script>

import { mapActions, mapState } from 'vuex'
import FormTicketDetails from '../ticket/TicketDetails.vue'
import OpenTicket from './OpenTicket'
import CardTicketTotal from '../../components/CardTicketTotal.vue'

export default {
    name: "UserMyTickets",
    data () {
        return {
            headers: [
                { text: 'Chamado', value: 'ticket_number', sortable: false},
                { text: 'Usuário', value: 'user', sortable: false},
                { text: 'Aberto em', value: 'created_at', sortable: false},
                { text: 'Assunto', value: 'subject', sortable: false},
                { text: 'Status', value: 'status.name', sortable: false},
                { text: 'Categoria', value: 'category.name', sortable: false},
                { text: 'Tipo', value: 'type.name', sortable: false},
                { text: 'Ações', value: 'actions', sortable: false}
            ],
            params: {},
            collections: [],
            meta: {},
            model: {},
            loading: false,
            showForm: false,
            showOpenTicket: false,
            graphs: [],
            message: '',
            error: false,
            deleting: false,
            showDelete: false,
            itemDelete: null,
            selectedGraph: null,
            showGraphs: true,
            onlyMyTickets: false,
        }
    },
    props: {
        myTickets: { type: Boolean, default: false },
        title: { type: String, default: "Lista de chamados" }
    },
    methods: {
        ...mapActions('user', ["ActionMyTicketsIndex"]),
        ...mapActions('ticket', ["ActionTicketsIndex", "ActionTicketsGraphTotalIndex", "ActionTicketsDestroy"]),
        getTicketTotal() {
            this.ActionTicketsGraphTotalIndex()
                .then((r) => {
                    this.graphs = r.data
                })
                .finally(() => {
                    //this.loading = false
                })

        },
        getData() {
            
            this.showForm = false;
            this.loading = !this.loading

            if(!this.myTickets) {

                const params = {
                    ...this.params,
                    with: "institution,category,status,type,author,assigned,attachments,comments.user,comments.attachments",
                    column_order: "created_at",
                    direction: "desc",
                }

                if(this.selectedGraph){
                    params.filter_relations = [
                        {
                            name:   'status',
                            key:    'status_step', 
                            value:  this.selectedGraph.step
                        }
                    ];
                }

                if(this.onlyMyTickets){
                    params.find_columns = {
                        assigned_to_id: this.user.id
                    };
                }
                
                this.ActionTicketsIndex(params)
                .then((r) => {
                    this.collections = r.data
                    this.meta = r.meta
                })
                .finally(() => {
                    this.loading = false;
                    this.showForm = false;
                    this.showOpenTicket = false;
                })

                return;

            }

            this.ActionMyTicketsIndex(this.params)
                .then((r) => {
                    this.collections = r.data
                    this.meta = r.meta
                })
                .finally(() => {
                    this.loading = false;
                    this.showForm = false;
                    this.showOpenTicket = false;
                })
            
        },
        onSave() {
            this.getData()
            this.getTicketTotal()
        },
        deleteItem()
        {
            this.deleting = true;
            this.message = '';
            this.error = false;

            this.ActionTicketsDestroy({ id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.showDelete = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        },
        clickGraph(ev){
            
            if((this.selectedGraph && ev) && this.selectedGraph.step == ev.step){
                this.selectedGraph = null;
            }else{
                this.selectedGraph = ev;
            }

            this.getData();
            this.getTicketTotal();
        }
    },
    computed: {
        ...mapState("auth", ["user"])
    },
    watch: {
        params(new_value, old_value) {
            if(new_value != old_value) {
                this.getData()
            }
        },
        model(new_value, old_value) {
            if(new_value !== old_value && (this.model && this.model.id))
            {
                this.showForm = true;
                this.message = '';

            } else if(Object.keys(this.model).length === 0){
                this.showForm = false;
            }
        },
        showForm(val) {
            if(!val) {
                this.model = {}
            }
        },
        onlyMyTickets(){
            this.getData();
        }
    },
    components: {
        FormTicketDetails,
        OpenTicket,
        CardTicketTotal
    },
    created() {
        this.getTicketTotal()
    }
}
</script>