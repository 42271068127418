<template>
    <v-col :cols="cols" :md="md">
      
      <emc-form-auto-complete 
        v-model="model"
        :items="items"
        :label="label"
        :outlined="outlined"
        :disabled="disabled"
        :error-messages="errorMessages"
        :error="error"
        :loading="loading"
        @onClick="getData"
        :hide-no-data="hideNoData"
        :clearable="clearable"
      >
        <template v-slot:item="{item}">
            <v-list-item-avatar>
                <EmcBaseAvatar 
                    :size="36"
                    :src="item.url_avatar"
                    :text="item.initials"
                />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
            </v-list-item-content>
        </template>
      </emc-form-auto-complete>
    </v-col>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'SelectUser',
    props: {
        cols: { type: [String, Number], default: 12 },
        md: { type: [String, Number], default: 6 },
        value: { type: [String, Number, Object] },
        errorMessages: { type: Object, required: false },
        error: { type: Boolean, required: false },
        disabled: { type: Boolean, required: false },
        outlined: { type: Boolean, required: false },
        item: { type: Object, required: false },
        hideNoData: { type: Boolean, required: false },
        label: { type: String, required: false },
        isUserInternal: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
    },
    data () {
      return {
        loading: false,
        collection: []
      }
    },
    computed: {
      model: {
          get() {
            return this.value
          },
          set(val) {
            this.$emit('input', val)
          }
      },
      items: {
        get() {
          if(this.collection.length > 0) {
              return this.collection
            } else {
              return [this.item]
            }
        },
        set(val) {
          this.collection = val
        }
      }
    },
    methods: {
      ...mapActions('user', ['ActionFindUsers']),
      getData() {
        
        if(this.items.length > 2)
          return;
        
        this.loading = true

        var params = {
            column_order: 'name'
        }

        if(this.isUserInternal) {
            params.find_columns = { is_internal: 1 }
        }

        this.ActionFindUsers(params)
                .then((r) => {
                    this.items = r.data
                })
                .finally(() => {
                    this.loading = false
                })
      }
    }
  }
</script>